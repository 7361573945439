import dynamic from 'next/dynamic'

import { RedTesting } from 'rvbr-www/red-testing'

import { useConditionalRendering } from '@/hooks/useConditionalRendering'

import { Hero } from '@/sections'

import { If, Banner, BannerButtons } from '@/components'

import { usePageStructure } from './usePageStructure'

import { HERO_TEXTS } from './constants'

const Footer = dynamic(() =>
  import('@/components/Footer/Footer').then((mod) => mod.Footer)
)

const Paywall = dynamic(() =>
  import('@/components/Paywall/Paywall').then((mod) => mod.Paywall)
)

const Help = dynamic(() =>
  import('@/sections/Help/Help').then((mod) => mod.Help)
)

const Plans = dynamic(() =>
  import('@/sections/Plans/Plans').then((mod) => mod.Plans)
)

const Benefits = dynamic(() =>
  import('../../sections/Benefits/Benefits').then((mod) => mod.Benefits)
)

const Callcenter = dynamic(() =>
  import('../../sections/Callcenter/Callcenter').then((mod) => mod.Callcenter)
)

const Services = dynamic(() =>
  import('../../sections/Services/Services').then((mod) => mod.Services)
)

const ContractingSteps = dynamic(() =>
  import('../../sections/ContractingSteps/ContractingSteps').then(
    (mod) => mod.ContractingSteps
  )
)

const PostpaidAdvantages = dynamic(() =>
  import('../../sections/PostpaidAdvantages/PostpaidAdvantages').then(
    (mod) => mod.PostpaidAdvantages
  )
)

export const PageStructure = () => {
  const { buildType, lazyLoad, isMobile, isPaywallOpen } = usePageStructure()
  const { isAffiliate } = useConditionalRendering()

  const redTestingServices = (
    <>
      <RedTesting
        experimentId="form_v2"
        control={<></>}
        variants={[
          <>
            <Plans key="plans-form_v2--b" />
            <PostpaidAdvantages />
          </>
        ]}
      />
      <Services />
    </>
  )

  return (
    <main>
      {isPaywallOpen && <Paywall />}

      <Banner
        isAnimated
        align="center"
        text="A Boa Vista mudou e agora é Equifax | BoaVista!"
      />

      <Hero
        variant="paid"
        title={HERO_TEXTS[buildType].title}
        subtitle={HERO_TEXTS[buildType].subtitle}
      />

      {!isMobile && redTestingServices}

      <If condition={lazyLoad}>
        {isMobile && redTestingServices}

        <ContractingSteps />
        <Benefits />
        <Help />
        <Callcenter />
        <Footer />
      </If>

      <If condition={!isAffiliate}>
        <Banner
          isFixed
          hasBorder
          hideTextOnMobile
          text="Central de vendas Equifax | BoaVista"
        >
          <BannerButtons />
        </Banner>
      </If>
    </main>
  )
}
