export const HERO_TEXTS = {
  organic: {
    title: 'Contrate agora mesmo seu plano Pós-Pago',
    subtitle:
      'Consulte CNPJ e CPF dos seus clientes e tome decisões para sua empresa com mais segurança.'
  },
  paid: {
    title: 'Faça consultas de CNPJ e CPF dos seus clientes',
    subtitle:
      'Tenha relatórios e análises precisas para a sua empresa tomar decisões com mais segurança.'
  }
}
